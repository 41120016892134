@media screen and (max-width: 1120px) {
  .sidebar_contents {
    display: none !important;
  }
  .sidebar_nav {
    justify-content: center;
    padding: 0 !important;
  }
  .nav1,
  .nav2,
  .nav3,
  .nav_notice,
  .nav_checklist {
    margin: 0 !important;
  }
}

@media (min-width: 992px) {
  .sidebar-top-m {
    display: none;
  }
  .nav_left_container {
    padding-top: 33px;
  }

  .cookie_container_m {
    display: none;
  }
}

@media (max-width: 991px) {
  .sidebar-top {
    display: none;
  }

  .sidebar-top-m {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 0;
    border-bottom: 1px solid #ececec;
  }

  .category_container {
    padding: 20px 0px;
  }

  .project_add_popup {
    width: 99%;
  }

  .badge_average {
    margin-top: 10px;
  }

  .cookie_container_p {
    display: none !important;
  }

  .cookie_container_m {
    width: calc(100% - 10px);
    border: 1px solid #cc9966;
    background-color: #cc9966;
    border-radius: 4px;
    text-align: center;
    margin: 5px;
    color: white;
    font-size: 12px;
    padding: 10px 0;
  }
  .project_add_popup {
    padding: 10px;
  }

  .studentsList__contents {
    width: 100%;
  }

  .popup__bottom__btn {
    margin-right: 14px;
  }

  .calendar_r {
    margin-top: 10px;
  }

  .projectOfStudentPopup {
    width: 100% !important;
  }

  .student_code_upload_btn {
    margin: 5px 0 0 0;
  }

  .select_btn {
    margin: 5px 0 0 0;
  }
  .student_top {
    margin-top: 15px;
  }

  .badge_container {
    margin-top: 40px !important;
  }
}

@media (min-width: 488px) {
  .login_m {
    display: none !important;
  }
}

@media (max-width: 487px) {
  .login_p {
    display: none !important;
  }

  .login_m {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
  }

  .login_logo_top {
    width: 100px;
    height: auto;
  }

  .login_logo_bottom {
    width: 170px;
    height: auto;
  }

  .login_input {
    width: 100%;
    height: 45px;
    font-size: 14px !important;
  }
  .login_input::placeholder {
    font-size: 14px !important;
  }

  .login_btn {
    width: 100%;
    height: 45px;
    font-size: 14px !important;
  }

  .sign-up-bottom {
    width: 100% !important;
  }

  .check-button {
    /* width: 40%; */
    width: 140px !important;
    margin: 0 10px 0 0 !important;
  }

  .cancel-button {
    /* width: 40%; */
    width: 140px !important;
    margin: 0 !important;
  }
}
