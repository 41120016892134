@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;400&family=Roboto+Slab&display=swap");

.studentOfProject_card {
  width: 100%;
  /* padding-left: 28px; */
}

.cookie {
  width: 56px;
  height: 56px;
}

.student_filter {
  left: 0;
}

.hidden_title > div {
  display: inline-block;
}

.project_in_student {
  width: 147px;
  height: 175px;
  justify-content: flex-start;
  padding-top: 17px;
}

.project_in_student_repeat {
  width: 147px;
  height: 175px;
  justify-content: space-between;
  padding-top: 17px;
  padding-bottom: 17px;
}

.download_item {
  width: 100%;
}

.student_submitted_image {
  width: 100%;
  height: auto;
  /* height: 300px; */
}

.image_uploaded_by_student {
  width: 100%;
  height: auto;
}

.download_text {
  text-decoration: underline;
}

.copy_input {
  border: none;
  background-color: transparent;
  width: 165px;
  font-family: "Roboto Slab", serif;
  letter-spacing: 1px;
}

.warning_transition {
  position: absolute;
  bottom: 50px;
  left: -220 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 8px 5px;
  color: red;
  font-size: 10px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  /* box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3); */
  box-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  transition: all 2s ease-in-out 1s;
  transform: translateX(-220px);
  -webkit-transition: all 2s ease-out 1s;
  -moz-transition: all 2s ease-out 1s;
  -ms-transition: all 2s ease-out 1s;
  -o-transition: all 2s ease-out 1s;
}

.warning_transition_on {
  transition: all 2s ease-in-out 1s;
  transform: translateX(0);
  -webkit-transition: all 2s ease-out 1s;
  -moz-transition: all 2s ease-out 1s;
  -ms-transition: all 2s ease-out 1s;
  -o-transition: all 2s ease-out 1s;
  /* -webkit-transition: all 2s ease-out 1s;
  -moz-transition: all 2s ease-out 1s;
  -ms-transition: all 2s ease-out 1s;
  -o-transition: all 2s ease-out 1s; */
  /* 　-webkit-transform: translate(220px, 0);
  　-moz-transform: translate(220px, 0);
  　-ms-transform: translate(220px, 0);
  　-o-transform: translate(220px, 0); */
}

.hs {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: red;
  font-size: 10px;
  padding: 8px 5px;
  width: 220px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.06);
  position: absolute;
  bottom: 65px;
  left: -220px;
}

.go {
  transform: translate(220px);
  transition-duration: 0.5s;
}
