.calendar_arrow {
  width: 17px;
  height: 17px;
  filter: opacity(0.5) drop-shadow(0 0 0 lightgray);
}

.arrow_wrapper {
  width: 32px;
  height: 32px;
  border: 1px solid #ececec;
}

.calendar_table > th {
  padding: 0 0 10px 0;
}

.calendar_table > tr > td {
  height: 40px;
}

.day_include {
  border: 1px solid #ececec;
}

.day_exclude {
  color: #979797;
}

.selectDate {
  background-color: #ffd37e !important;
}

.today {
  /* width: 2px; */
  /* height: 20px; */
  /* border-radius: 10px; */
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #c2e09d;
}

.calendar-mark {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-right: 4px;
}
