.t_head_wrapper {
  display: flex;
}

.t_head_wrapper_body {
  display: flex;
}

.table_body_container {
  max-height: 69vh;
}

.t_head_cell {
  background-color: #fff;
  min-height: 32px;
  width: 100px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  border-top: 1px solid #979797;
  border-left: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
}
.t_head_cell_project {
  background-color: #fff;
  min-height: 32px;
  width: 80px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  flex-basis: 80px;
  flex-grow: 0;
  flex-shrink: 0;
  border-top: 1px solid #979797;
  border-left: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t_head_cell3 {
  background-color: #fff;
  min-height: 32px;
  width: 150px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  flex-basis: 150px;
  flex-grow: 0;
  flex-shrink: 0;
  border-top: 1px solid #979797;
  border-left: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t_head_cell2 {
  min-height: 32px;
  width: 250px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  flex-basis: 250px;
  flex-grow: 0;
  flex-shrink: 0;
  border-top: 1px solid #979797;
  border-left: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-white {
  background-color: #fff !important;
}
.area {
  margin: 10px 0;
  width: 100px;
}
.area2 {
  margin: 10px 0;
  width: 180px;
}

/* 텍스트가 영역을 넘치도록 하는 속성 */
.white-space {
  white-space: nowrap;
}

/* 영역에 넘치는 요소를 숨겨주는 속성 */
.overflow {
  overflow: hidden;
}

/* 말줄임표 적용 */
.ellipsis {
  text-overflow: ellipsis;
}

.t_head_cell_diagonal {
  background-color: #fff;
  height: 32px;
  flex-basis: 100px !important;
  flex-grow: 0;
  flex-shrink: 0;
  border-top: 1px solid #979797;
  border-left: 1px solid #979797;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
}
