.sign__up__text {
  margin-bottom: 30px !important;
}

.sign-up-button {
  width: 230px;
  height: 54px;
  border: none;
  background-color: #ffeb00;
  border-radius: 7px;
}

.cancel-button {
  width: 230px;
  height: 54px;
  border: none;
  background-color: #bebebe;
  border-radius: 7px;
}

.sign-up-bottom {
  width: 500px;
  margin: 0 auto !important;
}

.id-input {
  width: 100%;
  border: 1px solid #dfdfdf;
  height: 54px;
  border-radius: 7px;
}

.id-input::placeholder {
  color: #b2b2b2;
}

.pw-input {
  width: 100%;
  border: 1px solid #dfdfdf;
  height: 54px;
  border-radius: 7px;
}

.pw-input::placeholder {
  color: #b2b2b2;
}

.check-same-name {
  top: -50px;
  left: 425px;
  color: #b2b2b2;
}

.check-same-email {
  top: 16px;
  left: 425px;
  color: #b2b2b2;
}

.email {
  margin-top: -20px !important;
}
