.toggle-container {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
}

.toggle-btn {
  width: 46px;
  height: 24px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  /* background-color: ${(props) => (!props.toggle ? "none" : "rgb(51,30,190)")}; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.toggle-transform {
  background-color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  position: absolute;
  left: 8%;
  transition: all 0.3s ease-in-out;
}

.toggle-transform-active {
  background-color: white;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  position: absolute;
  left: 5%;
  transition: all 0.5s ease-in-out;

  transform: translate(22px, 0);
  transition: all 0.3s ease-in-out;
}

.toggle-active-font {
  color: #f9a8a8;
}
