.report_tab {
  width: 150px;
  height: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  border-top: 1px solid #979797;
  background-color: #fff;
  color: #979797;
  font-weight: 500;
}

.report_tab_2 {
  width: 150px;
  height: 40px;
  border-top-left-radius: 5.5px;
  border-top-right-radius: 5.5px;
  border-bottom: none;
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  border-top: 1px solid #979797;
  background-color: #fff;
  color: #979797;
  font-weight: 500;
}

.report_select {
  width: 170px;
  height: 42px;
  /* padding: 8px 0; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 500;
  border: none !important;
  font-weight: bold;
  color: #fff;
}

.report_select_2 {
  width: 170px;
  height: 42px;
  /* padding: 8px 0; */
  border-top-left-radius: 5.5px;
  border-top-right-radius: 5.5px;
  font-weight: 500;
  border: none !important;
  font-weight: bold;
  color: #fff;
}

.report_body {
  height: calc(100vh - 170px);
  border: 1px solid #979797;
  padding: 20px 0;
}

.page_report_body_checklist {
  height: calc(100vh - 255px) !important;
}

.page_report_body_project {
  height: calc(100vh - 285px) !important;
}

.page_report_body {
  height: calc(100vh - 290px) !important;
}

.page_report_body_stack {
  overflow: auto;
  /* height: calc(100vh - 320px); */
  max-height: calc(100vh - 340px) !important;
  -ms-overflow-style: none;
}

.page_report_body_stack::-webkit-scrollbar {
  display: none !important;
}

.page_report_body_stack_project {
  overflow: auto;
  height: calc(100vh - 340px);
  max-height: calc(100vh - 340px) !important;
  -ms-overflow-style: none;
}

.page_report_body_stack_project::-webkit-scrollbar {
  display: none !important;
}

.stack_report_table {
  width: 100%;
}

.stack_report_top_wrapper {
  position: sticky;
  top: 0;
  justify-content: space-between;
  background-color: #fafafa;
  padding-bottom: 20px;
  z-index: 1;
}

.stack_report_table > tr > th {
  padding: 5px 0;
  border: 1px solid #979797;
}

.stack_report_table > tr > td {
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid #979797;
}

.stack_report_table2 {
  table-layout: fixed !important;
}

.stack_report_table2 > tr > th {
  padding: 5px 0;
  border: 1px solid #979797;
}

.stack_report_table2 > tr > td {
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid #979797;
}

.w-100 {
  width: 100px !important;
}
.w-200 {
  width: 200px !important;
}

.project_report_header {
  position: sticky;
  top: -1px;
  background-color: #fff;
}

.report_btn_blue {
  border-radius: 4px;
  border: 1px solid #3083eb;
  color: #3083eb;
  font-weight: 500;
  padding: 3px 8px;
  background-color: #fff;
}

.report_btn_red {
  border-radius: 4px;
  border: 1px solid #de4949;
  color: #de4949;
  font-weight: 500;
  padding: 3px 8px;
  background-color: #fff;
}

.report_btn_gray {
  border-radius: 4px;
  /* border: 1px solid #de4949; */
  border: none;
  color: #828282;
  font-weight: 500;
  padding: 5px 12px;
  background-color: #f2f2f2;
}

/* 누가기록 팝업 */
.stack_report_container {
  padding: 15px 40px;
  border-radius: 4px;
}

.students_wrapper {
  margin: 10px 20px;
  border: 1px solid #e7e7e7;
}

.select_student_list {
  width: calc(100% / 5);
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.select_stuent_c {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid #d2d2d2;
  position: relative;
  margin-right: 5px;
  cursor: pointer;
}

.select_student_c_2 {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid #d2d2d2;
  /* position: relative; */
  cursor: pointer;
}

.select_stuent_c_fill {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  top: 3px;
  left: 3px;
  background-color: #3083eb;
}

.report_textarea {
  width: 100%;
  border: none;
  outline: none;
  min-height: 150px;
  resize: vertical;
}

.report_main_bg {
  background-color: rgb(244 130 115);
}

.report_cancel_bg {
  background-color: #d2d2d2;
}

.stack_report_btn {
  border: none;
  padding: 6px 18px;
  border-radius: 4px;
  color: #fff;
}

.stack_tag_btn {
  width: 80px;
  min-width: 80px;
  height: 100%;
  margin-right: 10px;
  color: #979797;
  border: none;
  border-radius: 4px;
  padding: 0 10px;
  height: 30px;
}

.add_cookie_input {
  text-align: center;
  width: 55px;
  height: 40px;
  border: 1px solid #979797;
  margin-right: 5px;
}

.excel_img {
  filter: invert(10%) sepia(5%) saturate(158%) hue-rotate(314deg) brightness(100%) contrast(90%);
  width: 14px;
  height: 14px;
}

.record_tag_btn_width {
  min-width: 95px;
  /* min-width: calc((100% - 40px) / 5); */
}

.project_table_width_20 {
  width: 400px !important;
}

.project_table_width_100px {
  width: 100px !important;
}

/* 체크리스트 */

.folder_selectbar {
  display: flex;
  justify-content: space-between;
  width: 150px;
  border-radius: 4px;
  border: 1px solid #979797;
  padding: 5px 10px;
  text-align: center;
  /* flex: 1; */
}

.folder_selectbar_option {
  width: 150px;
  position: absolute;
  top: 35px;
  border-radius: 4px;
  border: 1px solid #979797;
  background-color: #fff;
  padding: 8px;
}

.selectbar_option_item {
  width: 100%;
  border-bottom: 1px solid #979797;
  padding-bottom: 8px;
}

/* 학생 리포트 체크리스트 */
/* table header */
.header_folder {
  width: 12%;
  height: 100%;
  padding: 4px 0;
  border: 1px solid #979797;
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
  font-weight: 500;
  /* overflow: hidden; */
}

.student_table_cell {
  min-height: 32px !important;
  width: 12%;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  display: flex;
  justify-content: center;
  align-items: center;
}

.student_table_cell2 {
  min-height: 32px !important;
  width: 25%;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.border_left {
  border-left: 1px solid #979797;
}

.folder_cell_options {
  width: 100%;
  border-bottom: 1px solid #979797;
  top: 44px;
  left: 0;
  background-color: #ececec;
}

.folder_item {
  text-align: center;
  padding: 8px 0;
}

/* 우리반 리포트 */
.project_table_body {
  position: sticky;
  left: 0;
  flex-basis: 250px;
  border-right: 1px solid #979797;
}

.stack_record_tag_btn {
  background-color: #e7e7e7;
  border: none;
  border-radius: 4px;
  color: #979797;
}

.stack_record_check {
  width: 3%;
  border: 1px solid #979797;
  border-right: none;
  border-top: none;
}

.align-fe {
  align-items: flex-end !important;
}

.project_record_header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fafafa;
}

.stack_record_unchecked {
  background-color: rgba(38, 97, 217, 0.4);
  padding: 0 10px;
  height: 30px;
}

.stack_record_cookie {
  border: 1px solid #dfc59b !important;
  /* background-color: #d4a369; */
  color: #dfc59b !important;
  background-color: #fff !important;
  /* opacity: 0.7; */
  padding: 0 10px;
  height: 30px;
  font-weight: 500;
}

.stack_record_cookie_use {
  border: 1px solid #de4949 !important;
  background-color: #fff !important;
  padding: 0 10px;
  height: 30px;
  font-weight: 500;
}

.brown {
  color: #fff !important;
  background-color: #9b7651 !important;
}

.bg-red {
  color: #fff !important;
  background-color: #de4949 !important;
}

.record_tag_btn_width2 {
  min-width: 95px;
}

/* AI쫑알이 */
.btn-ai-select {
  /* padding: 4px 20px; */
  width: 120px;
  padding: 4px 0;
  border-radius: 4px;
  background-color: #eee;
  border: none;
  color: #979797;
}

.font-12 {
  font-size: 12px;
}

.ai-feedback-generate-btn-disabled {
  padding: 10px 24px;
  background-color: #fff;
  border: none;
  color: #337ab7;
  border: 1px solid #337ab7;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.ai-feedback-generate-btn {
  padding: 10px 24px;
  background-color: #337ab7;
  border: none;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
}

.ai-prompt-input {
  width: 100%;
  outline: none;
  font-size: 14px;
  border: none;
  background: none;
  resize: none;
  background-color: #ececec;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  padding: 5px;
}

.font-blue {
  color: #337ab7;
}

.page_ai_feedback_body {
  /* height: calc(100vh - 580px) !important; */
  position: sticky;
  top: 0;
  overflow-y: scroll;
}

.ai-feedback-checklist-btn-uncheck {
  padding: 4px 14px;
  align-self: center;
  margin-right: 6px;
  border: none;
  border-radius: 4px;
  color: #979797;
}

.tag_btn_selected-project {
  background-color: rgb(246, 193, 66) !important;
  color: #fff !important;
  /* font-weight: 500; */
}

.tag_btn_fill_project {
  min-width: calc((100% - 40px) / 5);
  width: calc((100% - 40px) / 5);
  /* margin-top: 10px; */
  border: none;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.3);
  /* color: #fff; */
  background-color: rgba(255, 211, 126, 0.4);
  outline: none;
  padding: 3px 0;
}

.ai-feedback-checklist-btn-check {
  background-color: #b4d985 !important;
  color: #fff !important;
}

.bg-blue {
  background-color: #0d39bc;
}

.bg-yellow {
  background-color: rgb(246, 193, 66);
}

.bg-green {
  background-color: #b4d985 !important;
}

.border-yellow-1px {
  border: 1px solid rgb(246, 193, 66) !important;
}

.text-yellow {
  color: rgb(246, 193, 66);
}

.border-top-none {
  border-top: none !important;
}

.stack-record-tags-selected {
  color: #fff !important;
  font-weight: 500;
}

.ai-student-btn {
  width: 100%;
  border: none;
  padding: 6px 0;
  border-radius: 4px;
  margin-bottom: 6px;
  font-weight: 500;
}

.ai-copy-img {
  filter: invert(41%) sepia(79%) saturate(438%) hue-rotate(166deg) brightness(91%) contrast(93%);
}
