.studentContainer {
  padding: 0px 100px;
  display: flex;
  flex-wrap: wrap;
}

.studentBox {
  /* border: 1px solid red; */
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 10px 10px 0;
  padding: 0 20px;
}
