.modal_container {
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  right: 0;

  /* z-index: 100000 !important; */
}

.checklist_folder_popup_btn {
  padding: 5px 0;
  width: 130px;
  border-radius: 8px;
  border: none;
  background-color: #fff;
  /* background-color: #b4d985; */
  font-weight: 500;
}

.checklist_foler_btn {
  padding: 3px 20px;
  background-color: #336633;
  border-radius: 4px;
  color: #fff;
  border: none;
  font-weight: 300;
}

.checklist_foler_btn_cancel {
  padding: 3px 20px;
  background-color: #d2d2d2;
  /* border: 1px solid #979797; */
  border-radius: 4px;
  color: #fff;
  border: none;
  font-weight: 300;
}

.folder_input {
  width: 100%;
  border-radius: 0;
  border: 1px solid #979797;
  height: 30px;
}

.folder_selection {
  width: 150px;
  height: 30px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}

/* 학생페이지 파일 컨테이터 */
.file_ex_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ececec;
  background-color: #fff;
  padding: 5px;
}

/* MyModal.css */
.Mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99;
}
.Modal-body {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  /* width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center; */
}

@media (max-width: 992px) {
  .Modal-body {
    width: calc(100% - 30px) !important;
    /* margin: 0 10px !important; */
  }
}
