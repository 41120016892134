.add-notice {
  width: 180px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 50px; */
  border: 2px solid #cfd7e6;
  border-radius: 10px;
  font-size: 30px;
  background-color: #fff;
  color: #cfd7e6;
}

.notice-wrapper {
  height: calc(100vh - (41px + 93px));
  /* background-color: pink; */
}

.notice-title {
  width: 300px;
  height: 50px;
  border: none;
  background-color: #fafafa;
  font-size: 20px;
  font-weight: bold;
}

.add-notice-popup {
  width: 100%;
  min-height: calc(70vh - 150px);
  max-height: calc(70vh - 150px);
  /* border: 10px solid #979797; */
  background-color: #ececec;
  border-radius: 8px;
  padding: 20px;
  overflow-y: scroll;
}

.notice-view {
  width: 100%;
  height: 1000px;
  /* min-height: calc(70vh - 150px); */
  /* max-height: calc(70vh - 150px); */
  padding: 20px;
  overflow-y: scroll;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
}

.notice-input {
  width: 95%;
  height: 90%;
  border: none;
  outline: none;
  overflow-y: scroll;
}

.notice-item-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.notice-item-container {
  /* min-width: calc(100% * 1 / 6); */
  width: 200px;
  height: 80px;
  /* border: 1px solid red; */
  margin-bottom: 20px;
}

.notice-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px !important;
  width: calc(100% - 20px);
  height: 100%;
  border-radius: 10px;
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notice-item-tempsave {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px !important;
  width: calc(100% - 20px);
  height: 100%;
  border-radius: 10px;
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notice-plus {
  /* width: calc(100% - 20px); */
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-radius: 8px;
  border: 1px solid #cfd7e6;
  background-color: transparent;
  color: #ced6e5 !important;
  margin: 0 auto;
}

.notice-quill-container {
  /* height: 60vh; */
}

.notice-quill {
  height: calc(70vh - 150px);
  margin-bottom: 40px;
  /* height: calc(60vh); */
}

.quill-wrapper {
  /* height: calc(100vh - 200px); */
  /* border: 10px solid #979797; */
  /* border-radius: 4px; */
  /* overflow: hidden; */
}

.receiver {
  width: 150px;
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 18px;
  outline: none;
  border: none;
}

.student-item {
  /* width: calc(100vw * 1 / 12); */
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
}
.student-unchecked-btn {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white !important;
  color: rgba(0, 0, 0, 0.2);
}
.push_all_btn2 {
  width: 150px !important;
  border: 1px solid #de4949;
  color: #de4949;
  border-radius: 4px;
  background-color: #fafafa;
}

.notice_check_box {
  width: 20px;
  height: 20px;
  border: 1px solid #979797;
  margin-right: 5px;
}

.receiver-container {
  padding-bottom: 20px !important;
  /* border: 1px solid red; */
}

.nos-confirm {
  border: 1px solid #979797;
}

.nos-yellow {
  background-color: #ffd37e !important;
}

.nos-unchecked {
  border: 1px solid #979797;
  color: #979797 !important;
  background-color: #fff;
  opacity: 0.5;
}

.not-btn {
  /* width: 145px; */
  padding: 0 20px;
  height: 36px;
  border: none;
  border-radius: 7px;
  background-color: #f2f2f2;
  color: #828282;
}

.not-btn-border {
  padding: 0 20px;
  height: 36px;
  border: 1px solid #cc9966;
  border-radius: 7px;
  background-color: #fff;
  color: #cc9966;
  font-weight: bold;
}
