.project_add_popup {
  width: 590px;
  height: 83% !important;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 75px;
  margin-bottom: 75px;
  padding: 24px 40px 24px 40px;
  position: relative;
  /* border-radius: 4px; */
}

.popup_input {
  height: 33px;
  width: 386px;
  border-radius: unset;
  border: 1px solid #e7e7e7;
}

.popup_project_type_btn {
  border: 2px solid #3f4e5e;
  background-color: #3f4e5e;
  color: #fff;
  border-radius: 6px;
  width: 161px;
  height: 35px;
}

.project-quill {
  height: 200px;
  margin-bottom: 75px;
}

.popup_column_sb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup-scrore-input {
  width: 150px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  border: 1px solid #ececec;
}

.score-btn {
  /* width: 130px; */
  background-color: rgba(63, 75, 89);
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 4px 50px;
  margin-right: 10px;
}
.unsubmit-btn {
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 4px 20px;
  margin-right: 10px;
}

.img-container {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
  flex-wrap: wrap;
  width: 100%;
}

.img-add-wrapper {
  position: relative;
  width: 32%;
}

.img-delete {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 10px;
}

.img-add {
  height: auto;
  width: 100%;
}

.img-add2 {
  width: 300px;
  height: auto;
}

.add__file__btn {
  width: 130px;
  height: 38px;
  background-color: #b5bdc5;
  border-radius: 6px;
  color: #fff;
  border: none;
}

.target__student__btn {
  width: 189px;
  height: 38px;
  background-color: #b5bdc5;
  border-radius: 6px;
  color: #fff;
  border: none;
}

.studentsList__popup {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.studentsList__contents {
  width: 590px;
  height: 83%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 75px;
  margin-bottom: 75px;
}

.managing__options__contents {
  width: 33.333333%;
  height: 40px;
  background-color: #fff;
  display: inline-block;
  padding-left: 20px;
  padding-right: 6px;
  margin-bottom: 5px;
}

.managing__options__contents > div {
  position: absolute;
  top: 12px;
  right: 6px;
  width: 34px;
  height: 20px;
  margin-top: 5px;
}

.target_label {
  display: block;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 1px solid #bce0fd;
  background: #fff;
}

.target_label2 {
  display: block;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 1px solid #bce0fd;
  background: #fff;
  position: relative;
}

.target_label_y {
  display: block;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  /* border: 1px solid #bce0fd; */
  background: #ffd37e;
  position: relative;
}

.target_label_g {
  display: block;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  /* border: 1px solid #bce0fd; */
  background: #b4d985;
  position: relative;
}

.studentList_check {
  display: block;
  top: 3px;
  left: 6px;
  width: 7px;
  height: 12px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  position: absolute;
}
.studentList_check_y {
  display: block;
  top: 3px;
  left: 6px;
  width: 7px;
  height: 12px;
  border: solid #ffd37e;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  position: absolute;
}

.overflow-h {
  overflow-y: scroll;
  height: 100%;
}

.projectOfStudentPopup {
  position: relative;
  width: 590px;
  height: 80%;
  background-color: #f6f8fa;
  margin: 0 auto;
  margin-top: 75px;
  margin-bottom: 75px;
  padding: 25px 40px 24px 40px;
}

.project_list {
  width: 100%;
  /* max-height: 100px; */
  border: 1px solid #e7e7e7;
  overflow: auto;
  background-color: #fff;
  padding: 15px;
}

.project_list > p {
  word-break: break-all;
}

.feedback__text {
  width: 85%;
  height: 168px;
  border-radius: unset;
  border: 1px solid #e7e7e7;
  flex-direction: row;
  background-color: #fff;
  resize: none;
  outline: none;
  padding: 10px;
}

.download_img {
  width: 61px;
  height: 61px;
}

.project__sign__btn {
  width: 156px;
  height: 35px;
  border-radius: 7px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
}

.checklist-save-btn {
  width: 110px;
  height: 28px;
  border-radius: 7px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
}

.project_reject_btn {
  border: 1px solid #e56060;
  color: #e56060 !important;
}

.confirm_selected {
  border: none;
  background-color: #3f4e5e !important;
  color: #fff !important;
}

.confirm_reject_selected {
  border: none;
  background-color: #e56060 !important;
  color: #fff !important;
}

.cookie_circle {
  width: 67px;
  height: 69px;
  border-radius: 35px;
  border: 1px solid #3f4e5e;
}

.cookie_border_none {
  border: none !important;
}

.cookie1 {
  width: 36px;
  height: 39px;
}

.cookie2 {
  width: 47px;
  height: 48px;
}

.cookie3 {
  width: 51px;
  height: 49px;
}

.score_input {
  width: 95px;
  height: 44px;
  border: 1px solid #e7e7e7;
  padding: 0 15px;
}

.reject__reason__text {
  width: 420px;
  height: 160px;
  resize: none;
  outline: none;
  border: 1px solid #e7e7e7;
  padding: 10px;
}

.popup__bottom__btn {
  width: 91px;
  height: 34px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
}

.popup__bottom__btn2 {
  /* width: 91px; */
  padding: 0 15px;
  height: 34px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  border-radius: 7px;
}

.popup__bottom__btn__brown {
  width: 91px;
  height: 34px;
  border: 1px solid #d8d8d8;
  background-color: #cc9966;
  border-radius: 7px;
  color: #fff;
}

.popup__bottom__btn__gray {
  width: 91px;
  height: 34px;
  border: 1px solid #d8d8d8;
  background-color: #999999;
  border-radius: 7px;
  color: #fff;
}

.popup__btns {
  width: 100%;
}

.popup__album {
  position: fixed;
  width: 100%;
  /* padding: 3%; */
  top: 0;
  left: 0;
  z-index: 100;
  min-height: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-arrow {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(199deg) brightness(107%) contrast(101%);
}

.left-arrow-gray {
  filter: invert(35%) sepia(0%) saturate(0%) hue-rotate(264deg) brightness(106%) contrast(85%);
}

.album__in {
  overflow-x: auto;
  overflow-y: auto;
  /* box-shadow: 0.25rem 0.25rem rgb(225 225 225 / 8%) !important; */
  text-align: center;
  width: 60%;
  /* height: 90%; */
  max-height: 90%;
  /* margin: 0 auto; */
  /* overflow-y: initial !important; */
}
.album__in > img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.img_zoom {
  transform: scale(2);
  object-fit: cover;
  transform-origin: left top;
}

.album_in > video {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.popup-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  width: 420px;
  /* height: 60%; */
  background-color: #f6f8fa;
  margin: 0 auto;

  padding: 24px 13px 15px 13px;
  border-radius: 10px;
}

.popup-modal-wide {
  position: relative;
  width: 590px;
  height: 80%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 75px;
  margin-bottom: 75px;
  padding: 24px 40px 24px 40px;
}

.popup-flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
}

.flex-center {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.popup-badge-img-input {
  width: 140px;
  height: 140px;
  padding: 0 10px;
  border: 1px dashed #000;
  border-radius: 12px;
}

.add-badge-container {
  display: flex;
  margin: 0 auto;
  align-items: center;
}

.badge-title-input {
  width: 200px;
  border: none;
  border-bottom: 1px solid #000;
  background-color: #f7f7f7;
  margin-left: 20px;
  /* height: 30px; */
}

.badge-popup-btn {
  width: 75px;
  padding: 3px 0;
  border: 1px solid #d8d8d8;
  background-color: rgba(124, 124, 124);
  border-radius: 7px;
  color: #fff;
}

.badge-desc-input {
  width: 100%;
  background-color: #f6f8fa;
  border: none;
  border-bottom: 1px solid #000;
}

.badge-btn {
  width: 90px;
  padding: 4px 0;
  border-radius: 5px;
  background-color: rgba(124, 124, 124);
  border: none;
  color: #fff;
}

.badge-delete-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-btn-delete {
  width: 90px;
  padding: 4px 0;
  border-radius: 5px;
  background-color: rgba(176, 35, 24);
  border: none;
  color: #fff;
}

.badge-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-delete-btn {
  background-color: #ececec;
  border-radius: 6px;
  border: none;
  font-size: 13px;
  padding: 3px 7px;
  color: #949595;
}

.give-badges-btn {
  background-color: rgba(219, 219, 219);
  border: none;
  height: 30px;
  padding: 4px 12px;
  border-radius: 5px;
}

.modal-overlay {
  position: absolute;

  width: 100%;
  height: 100%;
  /* z-index: 6; */
}

/* 반복과제 팝업 */

.repeat-type-btn {
  border: none;
  padding: 4px 20px;
  border-radius: 4px;
}

.repeat-selectbar {
  width: 120px;
  height: 30px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  overflow-y: scroll;
  border: 1px solid #979797;
  border-radius: 4px;
}

.repeat-selectbar-open {
  position: absolute;
  margin-left: 25px;
  margin-top: 5px;
  padding: 0 10px;
  width: 120px;
  height: 200px;
  background-color: #fff;
  z-index: 10;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid #a5a5a4;
}

.repeat-select-item-n {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ececec;
}

.popup-checklist-textarea {
  outline: none;
  resize: none;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ececec;
}

.tag_input {
  border: none;
  border-bottom: 1px solid #979797;
  outline: none;
  background: none;
  font-size: 20px;
}

.modal_in_tag {
  width: calc((100% - 40px) / 5);
}

.mt-5 {
  margin-top: 5px !important;
}

.popup_rotate {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 100px;
  /* top: 10px; */
  filter: invert(100%) sepia(5%) saturate(86%) hue-rotate(122deg) brightness(113%) contrast(100%);
}

.popup_album_left_arrow {
  width: 45px;
  margin-left: 20px;
  height: auto;
  transform: rotate(180deg);
}

.popup_album_rotate {
  cursor: pointer;
  margin-top: 20px;
  top: 45px;
  left: 20px;
  filter: invert(99%) sepia(0%) saturate(6968%) hue-rotate(286deg) brightness(121%) contrast(99%);
}

.img_rotate {
  /* background-color: pink; */
  /* transform-origin: 50% 20%; */
}

/* 학생관리 팝업 */
.edit_student_btn {
  padding: 4px 26px;
  border-radius: 4px;
  font-weight: 500;
}

.border-none {
  border: none !important;
}

.students_edit_table {
  width: 100%;
}

.students_edit_table > tr > th {
  padding: 7px 0;
  border: 1px solid #979797;
  background-color: #ffd37e !important;
}

.students_add_wrapper {
  font-size: 25px;
  color: #979797;
  width: 100%;
  border: 1px solid #979797;
  height: 30px;
}

.sidebar_cookie_popup {
  padding: 20px;
  position: relative;
  width: 25vw;
  height: 40vh;
  overflow: auto;
  background-color: #fff;
  border-radius: 14px !important;
}
