.category_container {
  padding: 30px 52px 0 29px;
}

.category_searchInput {
  width: 254px;
  height: 41px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 26px;
  padding-left: 18px;
}
.category_searchInput2 {
  width: 200px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 26px;
  padding-left: 18px;
}

.search_icon {
  width: 22px;
  height: 27px;
  top: 7px;
  left: 18px;
}

.search_icon2 {
  width: 18px;
  height: auto;
  top: 6px;
  left: 15px;
}

.filter_btn {
  width: 150px;
  height: 41px;
  border: 1px solid #cfcfcf;
  border-radius: 20.5px;
  background-color: #fff;
}

.filter_btn2 {
  width: 200px;
  height: 32px;
  border: 1px solid #cfcfcf;
  border-radius: 20.5px;
  background-color: #fff;
}

.filter_btn_notice {
  width: 200px;
  height: 41px;
  border: 1px solid #cfcfcf;
  border-radius: 20.5px;
  background-color: #fff;
}

.filter_icon {
  width: 17px;
  height: 9px;
}

.push_all_btn {
  width: 152px;
  height: 36px;
  border: 1px solid #de4949;
  color: #de4949;
  border-radius: 4px;
  background-color: #fafafa;
  margin: 2.5px 0;
}

.all_resource_download_btn {
  width: 152px;
  height: 36px;
  border: 1px solid #979797;
  color: #979797;
  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_btn {
  /* width: 78px; */
  padding: 0 25px;
  height: 36px;
  background-color: #f2f2f2;
  border: none;
  color: #828282;
  border-radius: 7px;
}

.project_card {
  border-radius: 8px;
  border: 1.5px solid #cfd7e6;
  background-color: transparent;
  color: #ced6e5 !important;
}

.filter_contents {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  width: 142px;
  border-radius: 20px;
  z-index: 3;
  top: 45px;
  right: 8px;
  padding: 15px 0;
}
.filter_contents2 {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  width: 200px;
  border-radius: 20px;
  z-index: 3;
  top: 60px;
  right: 0px;
  padding: 15px 0;
}

.filter_contents_hidden {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  width: 142px;
  border-radius: 20px;
  z-index: 3;
  top: 45px;
  right: 0;
  padding: 15px 0;
}

.filter_contents_notice {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  width: 200px;
  border-radius: 20px;
  z-index: 3;
  top: 122px;
  right: 59px;
  padding: 15px 0;
}

.filter_inner {
  border-bottom: 1px solid #e7e7e7;
}

.filter_inner:hover {
  opacity: 0.6;
}

.filter_inner:last-child {
  border: none;
}

.select_all_btn {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 1px solid #979797;
  background-color: #fff;
}

.select_option_btn {
  width: 127px;
  height: 36px;
  border-radius: 18px;
  border: solid 1px #e2e2e2;
  background-color: #fff;
}

.border_blue {
  border: 1px solid #3366cc !important;
}

.text_blue {
  color: #3366cc !important;
}

.border_red {
  border: 1px solid #de4949 !important;
}

.text_red {
  color: #de4949 !important;
}
.select_submit_btn {
  width: 89px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  background-color: #fff;
}

.project_control_btn {
  /* width: 145px; */
  padding: 0 20px;
  height: 36px;
  border: none;
  border-radius: 7px;
  background-color: #f2f2f2;
  color: #828282;
}

.project_cookie_btn {
  /* width: 145px; */
  padding: 0 15px;
  height: 36px;
  border: none;
  border-radius: 7px;
  background-color: #cc9966;
  color: #fff;
  margin-left: 10px;
}

.project_close_btn {
  width: 107px;
  height: 36px;
  background-color: #f2f2f2;
  color: #828282;
  border-radius: 7px;
  border: none;
}

.select_all {
  width: 15px;
  height: 15px;
}

.project_inner_card {
  width: 147px;
  height: 125px;
  justify-content: space-between;
  /* justify-content: flex-start; */
  padding-top: 17px;
  padding-bottom: 17px;
}

.project_inner_card2 {
  width: 147px;
  height: 125px;
  /* justify-content: space-between; */
  padding-top: 10px;
  padding-bottom: 10px;
  /* border: 8px solid rgb(246, 193, 66); */
}

.project_inner_card3 {
  width: 147px;
  height: 125px;
  justify-content: space-between;
  padding-top: 17px;
}

.project_inner_card_notice {
  width: calc(100% - 20px);
  height: 80px;
  justify-content: flex-start;
  padding-top: 17px;
}

.card_check_btn {
  border: none;
  border-radius: 4px;
  width: 93px;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.7);
}

.card_check_btn_t {
  border: none;
  border-radius: 4px;
  width: 93px;
  height: 25px;
  background-color: #ffd37e;
}

.card_check_btn_inactive {
  border: none;
  border-radius: 4px;
  width: 93px;
  height: 25px;
  background: none;
}

.student_inner_card {
  width: 162px;
  height: 72px;
  justify-content: flex-start;
  padding-top: 17px;
}

.student_num {
  top: 16px;
  left: 25px;
  color: #c1cddb;
}

.popup_input_default {
  border-radius: unset;
  border: 1px solid #e7e7e7;
  padding: 0 13px;
  height: 33px;
  width: 163px;
}

.popup_student {
  width: 70px;
}

.popup_down {
  width: 16px;
  height: 9px;
  right: 7px;
  top: 12px;
}

.popup_gender_dropdown {
  top: 33px;
  left: 0;
  background-color: #fff;
  width: 70px;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
}

.popup_gender_dropdown_contents {
  padding: 6px 0;
  border-bottom: 1px solid #e7e7e7;
}

.popup_checkbox {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
}

.square_check {
  top: 2px;
  left: 4px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.check_card > .card_check_btn:hover {
  opacity: 1 !important;
}

.check_card > .card_check_btn:active {
  transform: scale(1) !important;
}

/* 쿠기쓰기 */
.use_cookie_input {
  width: 50px;
  margin-left: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: #f6f8fa;
  border-bottom: 1px solid #979797;
}

.rest_section {
  border-top: 0.5px solid #979797;
  margin: 0px 20px 15px 20px;
}

.m-10p {
  margin: 5px 0 !important;
}

.student_code_upload_btn {
  margin-left: 10px;
  padding: 0 10px;
  height: 34px;
  border-radius: 4px;
  outline: none;
  background-color: #fff;
  border: 1px solid #979797;
  color: #979797;
}
