.find__id {
  border: none;
  background-color: #ffe33a;
  /* width: 44%; */
  padding: 0 30px;
  height: 54px;
  border-radius: 7px;
}

.find__pw {
  border: none;
  background-color: #ffe33a;
  /* width: 44%; */
  padding: 0 30px;
  height: 54px;
  border-radius: 7px;
}

.check-button {
  width: 230px;
  height: 54px;
  border: none;
  background-color: #ffeb00;
  border-radius: 7px;
}
