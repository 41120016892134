.sidebar {
  background-color: #f7f7f7;
  /* background-color: salmon; */
  position: relative;
}
.sidebar_overlay {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.sidebar_top {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

.backToClassPage {
  text-decoration: none;
  all: unset;
}

.sidebar_nav {
  width: 100%;
  height: 58px;
  border: none;
  background-color: inherit;
  font-weight: 300;
}

.sidebar_nav > p {
  color: #232323;
}

.sidebar_nav > p:hover {
  color: #232323;
}

.nav1 {
  width: 25px;
  height: 29px;
}

.nav2 {
  width: 27px;
  height: 22px;
}

.nav3 {
  width: 25px;
  height: 14px;
}

.nav_notice {
  width: 29px;
  height: auto;
}

.nav_checklist {
  width: 22px;
  height: auto;
}

.cookieInput {
  width: 70px;
  border-bottom: 1px solid #993300;
  border-top: none;
  border-right: none;
  border-left: none;
  font-size: 17px;
  background-color: #f7f7f7;
}

.targetActionInput {
  width: 200px;
  border: none;
  border-bottom: 1px solid #993300;
  font-size: 17px;
  margin-top: 5px;
  background-color: #f7f7f7;
}

.editBtn {
  /* position: absolute; */
  /* bottom: 10px; */
  /* right: 10px; */
  padding: 4px 8px 6px 8px;
  border-radius: 4px;
  background-color: #494949;
  color: #fff;
  border: none;
  font-size: 12px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.hideBtn {
  /* position: absolute; */
  /* bottom: 10px; */
  /* left: 10px; */
  padding: 4px 8px 6px 8px;
  border-radius: 4px;
  background-color: #cc3333;
  color: #fff;
  border: none;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 새로운 side bar */
.sidebarBox {
  margin-top: -9999px;
  margin-bottom: -9999px;
  padding-top: 9999px;
  padding-bottom: 9999px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bodyContainer {
  overflow: hidden;
}

.sidebar-top {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 20px 0 20px 40px;
  display: flex;
  justify-content: space-between;
}

.sidebar-top2 {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 10px 0 10px 40px;
  display: flex;
  justify-content: space-between;
}
