@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700;900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&family=Song+Myung&display=swap"); */

:root {
  font-size: 10px;
}

body {
  background-color: #fafafa !important;
  font-family: "Noto Sans KR", sans-serif !important;
  color: #232323 !important;
}

p {
  margin: 0 !important;
}

input:focus {
  outline: none !important;
}

button {
  outline: none !important;
}

button:hover {
  opacity: 0.8 !important;
}

button:active {
  transform: scale(0.98) !important;
}

a {
  all: unset;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

a > span {
  text-decoration: none !important;
  color: #5b5b5b !important;
}

a > p {
  text-decoration: underline !important;
  color: #0067a3 !important;
}
.text-red {
  color: #de4949 !important;
}

.text-overflow {
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; */

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-overflow-student {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
}

.background-img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  width: 100%;
  height: 100px;
}

.bg-img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
}

.img-response {
  width: 100% !important;
  height: auto !important;
}

.active {
  background-color: #ececec !important;
}

.active .sidebar_contents {
  color: #232323 !important;
  font-weight: 500 !important;
}

.active > button {
  font-weight: 500 !important;
}

.scroll,
.modal-in-trans {
  overflow-y: auto !important;
}

.scroll::-webkit-scrollbar,
.modal-in2-p::-webkit-scrollbar {
  width: 6px;
  background: #ececec;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.scroll::-webkit-scrollbar-thumb,
.modal-in2-p::-webkit-scrollbar-thumb {
  width: 6px;
  background: #ddd;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.cursor {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-none {
  cursor: default !important;
}

.cursor-none:hover {
  opacity: 1 !important;
}

.cursor-none:active {
  transform: none !important;
}

.bg-checked {
  background-color: #474747 !important;
}

.width-100 {
  width: 100% !important;
}
/* display */

.display-b {
  display: block !important;
}

.display-n {
  display: none !important;
}

.display-ib {
  display: inline-block !important;
  vertical-align: top !important;
}

.display-f {
  display: flex !important;
}

.display-f-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.direction-column {
  flex-direction: column !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-between {
  align-content: space-between;
}

.align-center {
  align-items: center !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

/* position */

.position-r {
  position: relative !important;
}

.position-a {
  position: absolute !important;
}

.position-f {
  position: fixed !important;
}

/* opacity */

.opacity-0 {
  opacity: 0 !important;
}

/* state color */

.red {
  background-color: #f9a8a8 !important;
}

.blue {
  background-color: #0d39bc !important;
  /* background-color: #3366cc !important; */
}

.green {
  /* background-color: ; */
  background-color: #b4d985 !important;
}

.dark_green {
  background-color: #52905d !important;
}

.yellow {
  background-color: #ffd37e !important;
}

.gray {
  background-color: #ececec !important;
}
.border-none {
  border: none !important;
}

.border-yellow {
  border: 8px solid rgb(246, 193, 66) !important;
}

.border-red {
  border: 1px solid #de4949 !important;
}

.border-gray-light {
  border: 1px solid rgb(202, 202, 202) !important;
}

.border-gray {
  border: 8px solid rgb(202, 202, 202) !important;
}

.card-text-red {
  color: #e56060 !important;
}

.card-text-yellow {
  color: #9d824c !important;
}

.card-text-gray {
  color: #c1cddb !important;
}

.card-text-green {
  color: rgba(100, 125, 75) !important;
}

.text-underline {
  text-decoration: underline !important;
}

/* color */

.plus-color {
  color: #ced6e5 !important;
}

.white {
  color: #fff !important;
}

.black {
  color: #232323 !important;
}

.color-dark {
  color: #121212 !important;
}

.color-blue {
  color: #0d39bc !important;
}

.color-green {
  color: rgba(100, 125, 75) !important;
}

.color-white {
  color: #fff !important;
}

.color-light-black {
  color: #3f4e5e !important;
}

.color-gray {
  color: #b2b2b2 !important;
}

.color-dark-gray {
  color: #5b5b5b !important;
}

.color-dark-blue {
  color: #315d9f !important;
}

.color-red {
  color: #e56060 !important;
}

.color-yellow {
  color: #9d824c !important;
}

/* background-color */
.background-white {
  background-color: #fff !important;
}

.background-red-btn {
  background-color: #e56060 !important;
}

.background-green-btn {
  background-color: #b4d985 !important;
}

.background-red {
  background-color: #f9a8a8 !important;
}

.background-yellow {
  background-color: #ffd37e !important;
}

.background-green {
  background-color: #b4d985 !important;
}

.background-gray {
  background-color: #ececec !important;
}

.background-white {
  background-color: #fff !important;
}

.letter-spacing {
  letter-spacing: 1px;
}

/* font weight */

.font-300 {
  font-weight: 300 !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.font-900 {
  font-weight: 900 !important;
}

/* font size */

.font-10 {
  font-size: 1rem !important;
}

.font-11 {
  font-size: 1.1rem !important;
}

.font-12 {
  font-size: 1.2rem !important;
}

.font-13 {
  font-size: 1.3rem !important;
}

.font-15 {
  font-size: 1.5rem !important;
}

.font-16 {
  font-size: 1.6rem !important;
}

.font-18 {
  font-size: 1.8rem !important;
}

.font-20 {
  font-size: 2rem !important;
}

.font-23 {
  font-size: 2.3rem !important;
}

.font-25 {
  font-size: 2.5rem !important;
}

.font-29 {
  font-size: 2.9rem !important;
}

.font-30 {
  font-size: 3rem !important;
}

.font-34 {
  font-size: 3.4rem !important;
}

.font-40 {
  font-size: 4rem !important;
}

.font-60 {
  font-size: 6rem !important;
}

.font-gray {
  color: #979797 !important;
}

/* text */

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

/* margin */

.margin-auto {
  margin: 0 auto !important;
}

.mb-m5 {
  margin-bottom: -5px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mt-2 {
  margin-top: 2px !important;
}
.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-9 {
  margin-top: 9px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-9 {
  margin-bottom: 9px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-17 {
  margin-bottom: 17px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}

.mb-27 {
  margin-bottom: 27px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.mb-29 {
  margin-bottom: 29px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-33 {
  margin-bottom: 33px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-63 {
  margin-bottom: 63px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-277 {
  margin-left: 277px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-11 {
  margin-right: 11px !important;
}

.mr-13 {
  margin-right: 13px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-17 {
  margin-right: 17px !important;
}

.mr-18 {
  margin-right: 18px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-23 {
  margin-right: 23px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.mr-33 {
  margin-right: 33px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.mr-180 {
  margin-right: 180px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

/* padding */

.padding-0 {
  padding: 0 !important;
}

.p4 {
  padding: 4px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-11 {
  padding-top: 11px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-17 {
  padding-top: 17px !important;
}

.pt-20 {
  padding-top: 20px !important;
}
.pt-30 {
  padding-top: 30px !important;
}

.pt-33 {
  padding-top: 33px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-41 {
  padding-top: 41px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-11 {
  padding-bottom: 11px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-17 {
  padding-bottom: 17px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-17 {
  padding-left: 17px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-36 {
  padding-left: 36px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-47 {
  padding-left: 47px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-36 {
  padding-right: 36px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.modal {
  position: fixed;
  width: 100%;
  padding: 100px 0;
  z-index: 100;
  min-height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.12);
}

.modal-trans2 {
  position: fixed;
  width: 100%;
  padding: 100px 0;
  z-index: 100;
  min-height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.12);
}

.modal-in2-p {
  background-color: #fff;
  width: 30%;
  height: 70vh;
  margin: 0 auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0.25rem 0.25rem rgb(225 225 225 / 8%) !important;
}

.modal-btn-box {
  display: flex;
}
.modal-btn-left {
  flex: 1;
  height: 100%;
  border-right: 1px solid #ececec;
  padding: 20px 0;
}

.modal-in {
  background: #fff;
  /* height: 100%; */
  height: 70vh;
  width: 50%;
  margin: 0 auto;
  overflow: auto;
  text-align: left;
  padding: 20px 40px;
  position: relative;
  box-shadow: 0.25rem 0.25rem rgb(225 225 225 / 8%) !important;
}

.modal-in h3 {
  font-weight: normal;
  font-size: 1.8rem;
}

.modal-in h4 {
  /* font-weight: normal; */
  font-size: 1.6rem;
}

.modal-in p {
  font-size: 1.5rem;
}

.modal-in strong {
  font-weight: normal;
  margin-bottom: 10px !important;
}
.modal-in .box {
  overflow: auto;
  height: 200px;
}

.box-agree {
  bottom: 10px;
  text-align: center !important;
  align-items: center !important;
  margin-left: auto;
  margin-top: 100px;
}
.box-agree button {
  margin: 0 auto;
}

.box-notice-bottom {
  bottom: 20px;
  width: 100%;
  height: 60px;
  /* position: absolute; */
  text-align: center !important;
  margin-left: auto;
}

.box-notice-bottom .col-xs-6 {
  height: 60px;
  bottom: 20px;
  padding-top: 20px;
}

.border-right-gray {
  border-right: 1px solid #eee;
}

.box-notice-bottom p {
  cursor: pointer;
}

.cursor-p {
  cursor: pointer;
}

.unsubmit-text {
  font-size: 16px;
  font-weight: bold;
  color: #979797;
  /* margin: -8px 0 8px 0 !important; */
}

.font-500 {
  font-weight: 500 !important;
}

.text-break {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text_gray {
  color: #979797;
}

.text-deco-none {
  text-decoration: none !important;
}

.word-break {
  word-break: break-all;
}

@media (min-width: 992px) {
  .modal-in2-m {
    display: none;
  }
}

@media (max-width: 991px) {
  .modal-in2-p {
    display: none;
  }

  .modal-in2-m {
    background-color: #fff;
    width: 80%;
    height: 70vh;
    margin: 0 auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0.25rem 0.25rem rgb(225 225 225 / 8%) !important;
    /* padding: 0 20px 0 20px; */
  }
}

.student_add_input {
  /* width: 70%; */
  height: 45px;
  border: 1px solid #979797;
  border-radius: 4px;
  text-align: center;
  padding: 0 15px;
  font-size: 16px;
}

.add_student_wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.popup_input_default::-webkit-outer-spin-button,
.popup_input_default::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* quill custom */
.ql-align-center {
  text-align: center !important;
}

.txt_line {
  text-align: center;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px !important;
}

.margin-minus-8 {
  margin-top: -8px !important;
}

.font-12 {
  font-size: 12px;
}

.font-18 {
  font-size: 18px;
}

.font-bold {
  font-weight: 600 !important;
}

/* 초학급과제 생성 모달 */

/* 학생선택 모달창 */
.student_list_grid_container {
  width: 100%;
}

.student_list_item {
  display: inline-block;
  /* background-color: #ffd37e; */
  padding: 4px 0px;
  border-radius: 8px;
  cursor: pointer;
  width: calc(50% - 4px);
  margin-bottom: 4px;
}

.student_list_item:nth-child(2n) {
  margin-left: 4px;
}
.student_list_item:nth-child(2n-1) {
  margin-right: 4px;
}

.txt_line2 {
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-600 {
  font-weight: 600 !important;
}

.main-popup-draggable {
  width: 100%;
  border: 1px dashed #979797;
  padding: 6px 4px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.main-file-wrapper {
  width: 100%;
  padding: 6px 4px 12px 4px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
}

.main-select-students-header-wrapper {
  width: 40%;
  height: 100%;
  border-left: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}

.main-select-class-header {
  width: 100%;
  background-color: #ececec;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  position: sticky;
  top: 0;
}

.main-select-class-content {
  padding: 10px 20px;
  height: calc(100% - 56px);
  overflow-y: scroll;
}

.main-student-list-wrapper {
  width: 60%;
  height: 100%;
  border-radius: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
}

.main-student-contents-wrapper {
  width: 100%;
  background-color: #ececec;
  padding: 0 20px;
  height: 52px;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 600;
  justify-content: space-between;
  position: sticky;
  top: 0;
}

.main-students-contents {
  padding: 0 10px;
  height: calc(100% - 56px - 40px);
  overflow-y: scroll;
}
.main-height-100 {
  padding: 0 10px;
  height: calc(100% - 56px);
  overflow-y: scroll;
}

.min_height_1vh {
  min-height: calc(100vh - 71px) !important;
}

.studnet-search-input {
  width: 100%;
  margin: 0;
  border: none;
  font-weight: 400;
  padding: 4px 10px 4px 0;
  background: none;
}

.main-project-btn {
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 4px;
  color: #979797;
}

.main-check-wrapper {
  width: 16px;
  height: 16px;
  border: 1px solid #979797;
  cursor: pointer;
  position: relative;
}

.main-check-checked {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #ffd37e;
}

.color-black {
  color: #000 !important;
}

.main-target-student-btn {
  border-radius: 4px;
  background-color: #fff;
  padding: 6px 18px;
}

.main-border-yellow {
  border: 2px solid #ffd37e;
}
.main-border-red {
  border: 2px solid #f9a8a8;
}

.main-student-check-btn {
  background-color: #0d39bc;
  border: none;
  border-radius: 4px;
  color: #fff;
  padding: 6px 50px;
}

.bg-white {
  background-color: #ffffff;
}

.bg-beginner {
  background-color: #337ab7;
}

.bg-basic {
  background-color: #e2f0d9;
}

.bg-standard {
  background-color: #fff2cc;
}

.bg-standardplus {
  background-color: #f8cbac;
}

.bg-enterprise {
  background-color: #d4c8de;
}

.bg-red {
  background-color: #e56060 !important;
}

.font-basic {
  color: #e2f0d9 !important;
}

.plan-box {
  height: 340px;
  /* border: 1px solid #e2e2e2; */
  border-radius: 16px;
  /* background-color: #0067a3; */
  /* background-color: #fff !important; */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.15);

  padding: 30px;
}

.plan-desc {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plan-box p {
  color: #232323;
  font-size: 18px;
  font-weight: 600;
}

.margin-0 {
  margin: 0 !important;
}

.plan-btn-wrapper {
  display: flex;
  flex-direction: column;
}

.plan-btn-disable {
  padding: 4px 0;
  border: none;
  color: #979797;
  font-size: 16px;
  margin-top: 8px;
}

.plan-btn {
  padding: 4px 0;
  border: none;
  color: #232323;
  font-size: 16px;
  margin-top: 8px;
  /* border-radius: 4px; */
}

.border-focus {
  border: 3px solid #fb6858;
}

.check-box {
  width: 20px;
  height: 20px;
  border: 1px solid #979797;
  cursor: pointer;
}

.pay-input {
  width: 50%;
  border: 1px solid #fec001;
  font-size: 14px;
  padding: 4px;
}

.pay-input::placeholder {
  font-size: 11px;
}

.pay-btn {
  width: 100%;
  background-color: #fec001;
  padding: 10px 0;
  border: none;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.pay-card-type-select-2 {
  border: 1px solid #fec001;
  border: none;
  background-color: #fff2cc;
  padding: 10px 0;
  width: 100%;
  font-size: 17px;
  cursor: pointer;
  font-weight: 600;
  border: none;
}

.pay-card-type-select {
  font-weight: 600;
  text-align: center;
  line-height: 50px;
  color: black;
  border-radius: 5px;
  transition: all 0.2s;
  border: 1px solid #f8ce70;
}

.btnOrange {
  background: #fff2cc;
}

.btnOrange.btnPush {
  box-shadow: 0px 5px 0px 0px #f8ce70;
}

.btnPush:hover {
  margin-top: 15px;
  margin-bottom: 5px;
}

.btnOrange.btnPush:hover {
  box-shadow: 0px 0px 0px 0px #a66615;
}

.pay-card-type {
  border: 1px solid #ececec;
  background-color: #fff;
  padding: 10px 0;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
  color: #979797;
}

.grid-wrapper {
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}
.grid-wrapper2 {
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

.benefit-overlay {
  height: 340px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* z-index: 100; */
}

.benefit-overlay2 {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin-left: 15px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 25px 0 15px;
}
.benefit-overlay3 {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin-right: 15px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px 0 25px;
}

@media (max-width: 992px) {
  .benefit-overlay-plan {
    display: none;
  }
}

@media (min-width: 992px) {
  .benefit-overlay2 {
    display: none;
  }
  .benefit-overlay3 {
    display: none;
  }
}

.benefit-overlay-res {
  height: 340px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
}

@media (max-width: 469px) {
  .grid-wrapper2 :nth-child(3) {
    grid-column: auto;
  }
}

@media (min-width: 470px) {
  .grid-wrapper2 :nth-child(3) {
    grid-column: auto / span 2;
  }
  .benefit-overlay-res {
    visibility: hidden;
  }
}

@media (min-width: 680px) {
  .grid-wrapper2 :nth-child(3) {
    grid-column: auto;
  }
  .benefit-overlay-res {
    visibility: visible !important;
  }
}

@media (min-width: 992px) {
  .grid-wrapper2 :nth-child(3) {
    grid-column: auto / span 2 !important;
  }
  .benefit-overlay-res {
    visibility: hidden !important;
  }
}

.pay-organization-fixed-box {
  position: sticky;
  top: 20px;
  border-radius: 16px;
  background-color: #ececec;
  padding: 20px;
}

.content {
  min-height: calc(100vh - 69px);
  /* background-color: #e9ecef; */
}

.sticky-box {
  position: sticky !important;
  top: 20px;
  /* width: 200px; */
}

.pay-box-info {
  border-radius: 4px;
  background-color: #ececec;
  padding: 15px;
  /* height: 200px; */
}

.pay-box-info-blue {
  border-radius: 4px;
  background-color: #ddeaf5;
  padding: 15px;
  /* height: 200px; */
}

.pay-box-info-yellow {
  border-radius: 4px;
  background-color: #fbdf3c;
  padding: 15px;
  /* height: 200px; */
}

.pay-btn-2 {
  width: 100%;
  padding: 4px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
}

.border-yellow2 {
  border: 1px solid #fbdf3c;
  border-radius: 4px;
}

.discount-label {
  /* font-family: "Song Myung", serif; */
  font-weight: 600;
  border-radius: 4px;
  background-color: #fbdf3c;
  display: inline-block;
  width: 72px;
  height: 22px;
}

.font-song-myung {
  /* font-family: "Song Myung", serif; */
}

.back-btn-arrow {
  border: none;
  color: #979797;
  padding: 0 10px;
  border-radius: 4px;
  background: none;
  font-size: 20px;
}

/* 이메일 링크 접근시 구매 style */
.buy-container {
  width: 40%;
  border: 1px solid #979797;
  margin: 60px auto;
  padding: 20px 20px 30px 20px;
}

@media (max-width: 992px) {
  .buy-container {
    display: none !important;
    /* width: calc(100% - 40px);
    border: 1px solid #979797;
    margin: 20px;
    padding: 10px; */
  }

  .buy-container-m {
    display: block !important;
  }
  .payform-pc {
    display: none;
  }
}

.buy-container-m {
  display: none;
}

.btn-buy {
  width: 100%;
  padding: 4px 0;
  border: 1px solid #f8ce70;
  background-color: #fff2cc;
  color: #7f6001;
  font-size: 16px;
  font-weight: 500;
}

.btn-agree {
  flex: 1;
  padding: 8px 0;
  border: none;
}

.link-receipt:hover {
  text-decoration: underline !important;
}

/* addSerialNum page min-height */
.page-min-height {
  height: calc(100vh - 320px);
}

.text-hover-underline:hover {
  text-decoration: underline;
}

.text-hover-underline > a {
  color: #e56060 !important;
}

.google-login-btn {
  padding: 8px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #dadce0;
  color: #3c4043;

  letter-spacing: 0.25px;
}

/* border-b */
.border-b {
  border: none;
  border-bottom: 1px solid #979797;
}

.input-default {
  width: 100%;
  background: none;
}

.btn-border-blue {
  border: 1px solid #0d39bc;
  border-radius: 4px;
  /* background: none; */
  color: #0d39bc;
  font-weight: 600;
}

.btn-bg-blue {
  background-color: #0d39bc;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  border: none;
}

.bg-none {
  background: none;
}
