.main_background {
  /* background-color: #fafafa !important; */
  min-height: calc(100vh - 70px);
}

.main_background_show {
  min-height: calc(100vh - 70px);
  background-color: #fff;
}

.main_background_hidden {
  height: calc(100vh - 70px);
  background-color: rgba(0, 0, 0, 0.2);
}

.main_header {
  width: 100%;
  height: 70px;
  border-bottom: solid 1px #e2e2e2;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
}

.main_logo {
  width: 90px;
  height: 22px;
}

.main_user {
  width: 16px;
  height: 20px;
}

.card {
  width: 100%;
  height: 112px;
  background-color: #ececec;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  color: #232323;
}

.card-filesize {
  width: 100%;
  height: 112px;
  background-color: #ececec;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  color: #232323;
}

.card_checklist_normal {
  width: 100%;
  height: 112px;
  background-color: #ececec;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  color: #232323;
}

.card_checklist {
  width: 100%;
  height: 112px;
  background-color: #ececec;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  color: #232323;
  border: 7px solid #336633 !important;
}

.border-14 {
  border-radius: 14px;
}

.card2 {
  width: 100%;
  height: 112px;
  background-color: #ececec;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  padding: 17px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  color: #232323;
}

.state_check_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
}
.state_check_btn_wrapper {
  width: 100%;
  display: flex;
  margin-top: 5px;
  justify-content: center;
}

.card_plus {
  width: 100%;
  height: 112px;
  background-color: transparent;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #ced6e5;
}

.card:hover {
  opacity: 0.8;
}

.card:active {
  transform: scale(0.98);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  /* z-index: 5; */
}

.popup-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 11 !important;
}

.main_popup {
  position: relative;
  z-index: 100000 !important;
  width: 356px;
  height: 50%;
  background-color: #f6f8fa;
  margin: 0 auto;
  margin-top: 75px;
  padding: 24px 13px 0 13px;
}

.main_popup_flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 356px;
  height: 50%;
  background-color: #f6f8fa;
  margin: 0 auto;
  margin-top: 75px;
  padding: 24px 13px 15px 13px;
  border-radius: 10px;
}

.main_popup_flex_cookie {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 356px;
  height: 40%;
  background-color: #f6f8fa;
  margin: 0 auto;
  margin-top: 175px;
  padding: 24px 13px 15px 13px;
  border-radius: 10px;
}

.popup__bottom {
  text-align: center;
}

.popup__bottom__btn {
  width: 91px;
  height: 34px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  border-radius: 7px;
}

.class__add__input {
  width: 100%;
  height: 33px;
  border: 1px solid #e7e7e7;
}

.logout {
  top: 30px;
  border: 1px solid #e7e7e7;
  padding-top: 10px;
  border-radius: 7px;
  background-color: #fff;
  position: absolute;
  right: 15px;
}
.logout a,
.logout a:hover,
.logout a:focus {
  color: #232323 !important;
  text-decoration: none !important;
}

.logout__text {
  padding: 0 18px 10px 18px;
}

.main_card_handler {
  width: 55%;
}

.card_handler_btn {
  width: 78px;
  height: 36px;
  border-radius: 18px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
}

.card_handler_submit {
  width: 69px;
  height: 36px;
  background-color: #315d9f;
  border-radius: 4px;
  border: none;
}

.card_handler_submit2 {
  padding: 0 15px;
  height: 36px;
  background-color: #315d9f;
  border-radius: 4px;
  border: none;
}

.card_handler_submit_delete {
  padding: 0 15px;
  height: 36px;
  border: 1px solid #de4949;
  border-radius: 4px;
  background-color: #fafafa;
  color: #de4949;
}

.card_handler_cancel {
  /* width: 69px; */
  height: 36px;
  border: solid 1px #315d9f;
  background-color: #fff;
  border-radius: 4px;
}

.main_card_header {
  height: 36px;
}

.check {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid #cbd6e6;
  top: 6px;
  left: 15px;
  background-color: #fff;
  /* z-index: 2; */
}

.check__inside {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
}

.left {
  width: 10px;
  height: auto;
  top: 50%;
  left: 20px;
  margin-top: -16px;
}

.right {
  width: 10px;
  height: auto;
  top: 50%;
  right: 20px;
  margin-top: -16px;
}

.qr_box {
  width: 50%;
  aspect-ratio: 1;
}

.qr {
  width: 100%;
  height: auto;
}

.student-badge-box {
  width: 30px;
  height: 30px;
  border: 1px dashed #979797;
  margin-left: 10px;
  border-radius: 5px;
}

.student-badge-box-btn {
  width: 30px;
  height: 30px;
  border: 1px dashed #979797;
  margin-left: 10px;
  border-radius: 5px;
  padding: 0;
}

.student-badge-box-inactive {
  width: 30px;
  height: 30px;
  border: 1px dashed #979797;
  margin-left: 10px;
  border-radius: 5px;
}

.hidden {
  visibility: hidden;
}

.file-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  width: 140px;
  height: 140px;
  padding: 0 10px;
  border: 1px dashed #000;
  border-radius: 12px;
}

.file-img {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  width: 140px;
  height: 140px;
  /* padding: 0 10px; */
  /* border: 1px dashed #000; */
  border-radius: 12px;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  /* color: white; */
}

.file-drop-dragging-over-target {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 12px;
  border: 1px dashed #979797;
  color: #979797;
  box-shadow: 0 0 13px 3px #979797;
}

.badge-item-container {
  width: 50px;
  height: 50px;
  border: 1px dashed #979797;
  margin-right: 5px;
  border-radius: 6px;
}

.badge-item-img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin-right: 5px;
  border-radius: 6px;
  background-color: #fff;
}

.spinner {
  border: 3px solid #fff7b5;
  border-top-color: #fbe404;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: spin 1s linear infinite;
}

.spinner2 {
  border: 3px solid #979797;
  border-top-color: #fff;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  animation: spin 1s linear infinite;
}

.spinner3 {
  border: 3px solid #d2d2d2;
  border-top-color: #979797;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

.loading_dots {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.loading_dots div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #fedb97;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.loading_dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.loading_dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

.img-lock {
  width: 50px;
  height: auto;
  filter: opacity(0.5) drop-shadow(0 0 0 #fff);
}

.border-repeat-yellow {
  box-sizing: border-box;
  border: 8px solid rgb(246, 193, 66);
}

.repeat-type-icon {
  position: absolute;
  right: 24px;
  top: 12px;
  filter: opacity(0.5) drop-shadow(0 0 0 gray);
}

.repeat-lock {
  width: 100%;
  display: flex;
  justify-content: center;
}

.repeat-lock-date {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.tag_btn {
  min-width: calc((100% - 40px) / 5);
  width: calc((100% - 40px) / 5);
  border: 1px dashed #979797;
  border-radius: 4px;
  background-color: #fff;
  outline: none;
  padding: 3px 0;
  color: #979797;
}

.tag_btn2 {
  min-width: 80px;
  width: 80px;
  border: 1px dashed #979797;
  border-radius: 4px;
  background-color: #fff;
  outline: none;
  padding: 3px 0;
  color: #979797;
}

.tag_btn_block {
  min-width: calc((100% - 40px) / 5);
  width: calc((100% - 40px) / 5);
  /* border: 1px dashed #979797; */
  border: none;
  border-radius: 4px;
  background-color: #fff;
  outline: none;
  padding: 3px 0;
  color: #979797;
}

.tag_btn2 {
  min-width: 60px;
  border: 1px dashed #979797;
  border-radius: 4px;
  background-color: #fff;
  outline: none;
  padding: 3px 0;
  color: #979797;
}

.tag_btn_fill {
  min-width: calc((100% - 40px) / 5);
  width: calc((100% - 40px) / 5);
  /* margin-top: 10px; */
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: rgba(82, 144, 93, 0.4);
  outline: none;
  padding: 3px 0;
}

.tag_btn_fill2 {
  min-width: 60px;
  /* margin-top: 10px; */
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: rgba(82, 144, 93, 0.4);
  outline: none;
  padding: 3px 0;
}

.tag_btn_selected {
  background-color: #52905d !important;
  color: #fff !important;
  /* font-weight: 500; */
}

.modal_tag_btn {
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 3px 0;
  color: rgb(151, 151, 151);
}
.btn_blue {
  width: 152px;
  height: 36px;
  border-radius: 4px;
  border: none;
  border: 1px solid #3366cc;
  background-color: #fff;
  color: #3366cc;
  font-weight: bold;
}

.btn_brown {
  border-radius: 4px;
  border: 1px solid rgb(155, 118, 81);
  color: rgb(155, 118, 81);
  background-color: #fff;
  font-weight: bold;
}

.btn_red {
  border-radius: 4px;
  border: 1px solid #e56060 !important;
  color: #e56060 !important;
  background-color: #fff;
  font-weight: bold;
}

.web_frame {
  -ms-overflow-style: none;
}
.web_frame::-webkit-scrollbar {
  display: none;
}

.btn_small {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  color: #828282;
  /* border: 1px solid #3366cc; */
  /* background-color: #fff; */
}

.btn_name {
  padding: 7px 20px;
  border-radius: 4px;
  border: none;
  border: 1px solid #232323;
  background-color: #fff;
}

.btn_copy {
  border-radius: 4px;
  /* border: 1px solid red; */
  cursor: pointer;
}
.img_copy {
  filter: invert(9%) sepia(20%) saturate(16%) hue-rotate(346deg) brightness(97%) contrast(89%);
}

.hidden_btn {
  border: 1px solid #979797 !important;
  background-color: #fff !important;
  font-weight: 500 !important;
}

.easy_cookie_container {
  width: 100%;
  /* width: calc(100% - 110px); */
}

.easy_check_cookie {
  background-color: #fff;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 4px;
}

.modal_mobile {
  width: 100%;
  display: flex;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  margin-top: 20px;
}

.students_stack_date_input {
  width: 150px;
  height: 35px;
  padding: 0 5px;
  color: #979797;
  border-radius: 4px;
  border: none;
}

.add_student_popup_container {
  padding: 0 30px 30px 30px;
  position: relative;
  overflow: auto;
  background-color: #fff;
}

.add_students_modal_head {
  /* position: sticky; */
  /* top: 0;
  z-index: 100;
  background-color: #fff;
  padding-top: 10px; */
}

.students_code_download_btn {
  margin: 0;
  border: 1px solid #232323;
  height: 30px;
  color: #232323;
  border-radius: 4px;
  background-color: #fff;
}

.students_code_form_btn {
  margin: 0;
  background-color: #232323;
  height: 30px;
  color: #fff;
  border-radius: 4px;
}

.students_delete {
  margin: 0;
  height: 30px !important;
  width: 80px !important;
  background-color: #de4949;
  color: #fff;
}

.folder_title_input {
  width: 200px;
  height: 36px;
  margin-left: 10px;
  font-weight: bold;
  padding-left: 10px;
  margin-right: 10px;
}

.folder_title_input::placeholder {
  font-size: 14px !important;
  font-weight: 400;
}

.font-14 {
  font-size: 14px !important;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 992px) {
  .main_header_m {
    display: none !important;
    background-color: #fafafa;
  }

  .classlist_card_edit {
    margin: 0;
  }
}

@media (max-width: 991px) {
  .main_header {
    display: none !important;
  }

  .main_header_m {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 10px;
    background-color: #fafafa;
  }

  .classlist_card_edit {
    margin-top: 20px;
  }
}

.home_btn {
  border: 1px solid #979797;
  background-color: #fafafa;
  font-weight: 500;
  padding: 0px 12px !important;
}

.filesize-animation {
  position: relative;
  animation: slide 1s ease-in-out;
}

@keyframes slide {
  from {
    left: -5000px;
  }
  to {
    left: 0px;
  }
}

.bg-white {
  background-color: #fff;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}
