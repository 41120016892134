.login_background {
  background-color: #fafafa !important;
  height: 100vh;
  position: relative;
}

.login_logo_top {
  width: 146px;
  height: 145px;
}

.login_logo_bottom {
  width: 204px;
  height: 51px;
}

.login_input {
  width: 467px;
  height: 40px;
  border: 1px solid #dfdfdf;
  border-radius: 7px;
  padding: 0 36px;
}

.login_input::placeholder {
  color: #b2b2b2 !important;
}

.login_btn {
  width: 467px;
  height: 40px;
  border-radius: 7px;
  border: none;
  /* background-color: #bebebe; */
}

.kakao {
  background-color: #ffeb00;
  color: #401c28;
}

.sign-up::after {
  content: "|";
  margin: 0 22px 2px 21px;
}

.kakao_img {
  transform: scale(0.5);
  width: 40px;
  height: 40px;
}

.link_signout > p {
  text-decoration: none !important;
  color: black !important;
}
